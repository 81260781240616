import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { stopAudio } from "../../../../Store/Dispatcher/behaviour";
import { RootState } from "../../../../Store/Reducers";
import HomeScreen from "./Components/Home";
import AD from "./Components/Home/AD/index";
import Messages from "./Components/Messages";
import Container from "./styles";
import QuickButtons from "./Components/Messages/QuickButtons";

const Content = () => {
  const messages = useSelector((state: RootState) => state.messages);

  // Track the screen width to determine if it is a mobile device
  const [isMobile, setIsMobile] = useState(false);

  const checkHeight = () => {
    const bodyElement = document.getElementById("corover-body");
    return bodyElement ? bodyElement.offsetHeight > 310 : false;
  };

  // Effect to update the mobile detection based on window size
  useEffect(() => {
    const handleResize = () => {
      // Check if the window width is less than or equal to 768px (common mobile breakpoint)
      setIsMobile(window.innerWidth <= 768);
    };

    // Initialize on mount
    handleResize();

    // Set up event listener for window resizing
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Handle audio player setup
  useEffect(() => {
    const bodyElement = document.getElementById("corover-body");
    let audioPlayer: any = document.getElementById("myaudio") as HTMLAudioElement;

    if (audioPlayer) {
      audioPlayer.addEventListener("ended", () => {
        stopAudio();
      }, false);
    }

    return () => {
      if (audioPlayer) {
        audioPlayer.removeEventListener("ended", () => {
          stopAudio();
        }, false);
      }
    };
  }, []);

  return (
    <Container>
      {messages.length > 0 ? (
        <Messages messages={messages} />
      ) : (
        <>
          {!checkHeight() && <AD />} {/* AD should not show on mobile */}
          {checkHeight() && <HomeScreen />}
        </>
      )}
    </Container>
  );
};

export default Content;
