import React, { useEffect, useState } from "react";
import useGetReduxState from "../../../../../../../Hooks/useGetReduxState";
import translations from "../translations";
import { setJourney } from "../../../../../../../Store/Dispatcher/journey";
import useSendQuery from "../../../../../../../Hooks/useSendQuery/useSendQuery";
import AdUnit from "./styles";

const AD_UNIT_CONFIG = {
  DIV_ID: "div-gpt-ad-1737542509635-0", // Updated div ID
  DIMENSIONS: [300, 250],
  DISPLAY_DELAY: 4000,
  GPT_SCRIPT_URL: "https://securepubads.g.doubleclick.net/tag/js/gpt.js",
  AD_UNIT_PATH: "/37179215/GPT_NWEB_HOME_BOTTOM_RIGHT", // Updated ad unit path
};

const AD = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isAdVisible, setIsAdVisible] = useState(false);
  const [error, setError] = useState(null);

  const lang = useGetReduxState().behaviour.lang;
  const langText = translations[lang];
  const { sendQueryPayload } = useSendQuery(() => {});

  // Function to load the GPT script
  const loadGPTScript = () => {
    return new Promise<void>((resolve, reject) => {
      if (window.googletag) {
        resolve(undefined);
        return;
      }
  
      const script = document.createElement("script");
      script.src = AD_UNIT_CONFIG.GPT_SCRIPT_URL;
      script.async = true;
      script.onload = () => resolve(undefined);
      script.onerror = reject;
      document.head.appendChild(script);
    });
  };

  // Function to define the GPT slot
  const defineGPTSlot = () => {
    window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(function () {
      try {
        googletag
          .defineSlot(
            AD_UNIT_CONFIG.AD_UNIT_PATH, 
            AD_UNIT_CONFIG.DIMENSIONS, 
            AD_UNIT_CONFIG.DIV_ID
          )
          .addService(googletag.pubads());
        googletag.pubads().enableSingleRequest();
        googletag.enableServices();
      } catch (error) {
        console.error("Error defining GPT slot:", error);
        setError("Failed to initialize advertisement");
      }
    });
  };

  // Initialize GPT and fetch ad unit data
  useEffect(() => {
    const initializeAd = async () => {
      try {
        await loadGPTScript();
        setIsScriptLoaded(true);
        defineGPTSlot();
      } catch (error) {
        console.error("Failed to initialize ad:", error);
        setError("Failed to load advertisement");
      }
    };

    initializeAd();

    // Cleanup
    return () => {
      if (window.googletag && googletag.destroySlots) {
        googletag.cmd.push(() => googletag.destroySlots());
      }
    };
  }, []);

  // Display ad after delay
  useEffect(() => {
    if (isScriptLoaded) {
      const timer = setTimeout(() => {
        setIsAdVisible(true);
        googletag.cmd.push(function () {
          googletag.display(AD_UNIT_CONFIG.DIV_ID);
        });
      }, AD_UNIT_CONFIG.DISPLAY_DELAY);

      return () => clearTimeout(timer);
    }
  }, [isScriptLoaded]);

  if (error) {
    return null; // Or return a fallback UI
  }

  return (
    <AdUnit>
      {isAdVisible && (
        <div
          id={AD_UNIT_CONFIG.DIV_ID}
          style={{ 
            width: `${AD_UNIT_CONFIG.DIMENSIONS[0]}px`, 
            height: `${AD_UNIT_CONFIG.DIMENSIONS[1]}px`,
            margin: "0 auto" 
          }}
        />
      )}
    </AdUnit>
  );
};

export default AD;
