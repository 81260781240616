import React, { useState, useEffect } from "react";
import FooterContainer from "./Footer.styles";
const img1 = require("../../Assets/Local/corover.png");
const img2 = require("../../Assets/Local/Bharat.png");
const Footer = () => {
  const [isShort, setIsShort] = useState(false);

  const checkHeight = () => {
    const bodyElement = document.getElementById("corover-body");
    return bodyElement ? bodyElement.offsetHeight < 310 : false;
  };

  useEffect(() => {
    setIsShort(checkHeight());

    const handleResize = () => {
      setIsShort(checkHeight());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); 
  return (
    <FooterContainer isShort={isShort}>
      <a
        href="https://corover.ai/"
        target="_blank"
        title="CoRover® Pvt. Ltd."
        tabIndex={0}
        aria-label="Powered by CoRover"
      >
        <img src={img1} alt="CoRover logo" />
        <sup>®</sup>
      </a>
      <span
            style={{
              fontSize: "small",
              margin: "0px 5px",
            }}> | </span>
      <a
        href="https://builder.corover.ai/#/auth/signin"
        target="_blank"
        title="CoRover® Pvt. Ltd."
        tabIndex={0}
        aria-label="BharatGPT"
      >
        <img src={img2} alt="BharatGPT logo" />
        <sup>®</sup>
        </a>
      {/* Optionally include other content, like the Bharat image, or more text */}
      {/* <h3>|</h3>
      <img style={{width:'70px'}} src={img2} alt="Bharat logo" /> */}
      
    </FooterContainer>
  );
};

export default Footer;
