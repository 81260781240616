import { ENTER_PNR } from "../../../../Hooks/useSendQuery/utils/constants";
import store from "../../../../Store";

const getForPNR = () => {};

export const getPlaceholder = (flowStep) => {
  const lang = store.getState().behaviour.lang;
  switch (flowStep) {
    case ENTER_PNR: {
      if (lang === "en") return "Enter Your PNR Number";

      if (lang === "hi") return "अपना PNR नंबर दर्ज करें";

      if (lang === "gu") return "તમારો પી. એન. આર. નંબર દાખલ કરો";
      break;
    }

    default: {
      if (lang === "en") return "Type here to chat...";
      if (lang === "hi") return "यहाँ टाइप करें...";
      if (lang === "gu") return "અહીં ટાઈપ કરો...";
      break;
    }
  }
};
