import styled from "styled-components";

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
  height: 18px;
  overflow-y: hidden;


  span {
    font-size: ${(props) => (props.isShort ? "13px" : "10px")};
    margin-right: 4px;
    display: block;
  }

  a {
    text-decoration: none;
    display: block;
    cursor: pointer;

    img {
      width: ${(props) => (props.isShort ? "56px" : "54px")}; 
      margin-bottom: 1px;
    }

    sup {
      color: rgb(255, 87, 34);
      font-size: ${(props) => (props.isShort ? "14px" : "11px")};
    }
  }

  img {
    margin-bottom: 1px;
  }

  h3{
    margin-left: 10px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
`;

export default FooterContainer;
