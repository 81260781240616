import React from "react";
import Container from "./styles";
import Content from "./Components/Content";

import Sender from "./Components/Sender";
import useSendQuery from "../../Hooks/useSendQuery/useSendQuery";
import useGetReduxState from "../../Hooks/useGetReduxState";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../ErrorFallback";
import QuickButtons from "./Components/Content/Components/Messages/QuickButtons";

interface BodyProps {
    support: boolean; // Adjust the type as necessary
  }
  
  const Body: React.FC<BodyProps> = ({ support }) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString.toLowerCase());
  const { sendQueryPayload } = useSendQuery(() => {});
  const handleSendQueryPayload = ({ query, inputType, data }) => {
    sendQueryPayload({ data: data, query: query, inputType: inputType });
  };
  const { behaviour: { fullScreenComponent } } = useGetReduxState();
  const messages = useGetReduxState().messages;
  //
  const getRender = () => {
    if (fullScreenComponent.component && fullScreenComponent.type !== "LOGIN") {
            return fullScreenComponent.component;
          } else {
      return (
        <React.Fragment>
          {messages.length > 0 && !support && <QuickButtons />}
          <Content />
          <Sender handleSendQueryPayload={handleSendQueryPayload} />
        </React.Fragment>
      );
    }
  };
  return (
    <Container id="corover-body">
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => { /* Reset Logic */ }}>
        {getRender()}
        {fullScreenComponent.component && fullScreenComponent.type === "LOGIN" && fullScreenComponent.component}
      </ErrorBoundary>
      
    </Container>
  );
};

export default Body;
