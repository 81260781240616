import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  padding: ${(props) => (props.isCheckHeight ? '20px' : '10px')};
  scale:${(props) => (props.isCheckHeight ? '1' : '0.93')};
  .row {
    display: flex;
    justify-content: center;
    column-gap: ${(props) => (props.isCheckHeight ? '30px' : '10px')};
    margin-bottom: ${(props) => (props.isCheckHeight ? '30px' : '10px')};

    &:first-child {
      margin-bottom: ${(props) => (props.isCheckHeight ? '30px' : '10px')};
    }
  }

  .tab,
  .tab-active {
    width: 45%;
    border: 1px solid gainsboro;
    border-radius: 8px;
    /* text-align: center; */
    /* height: 130px; */
    /* width: 134px; */
    /* display: flex; */
    /* flex-direction: column;
    justify-content: center;
    align-items: center; */
    padding: 8px 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    cursor: pointer;

    .head {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      p {
        font-size: 20px;
        /* color: #2b2b2b; */
        font-weight: 400;
      }

      h2 {
        font-size: 18px;
        width: 35px;
        height: 35px;
        border: 1px solid #a7a1a1;
        border-radius: 50%;
        padding: 7px 6px;
        margin-right: 8px;
      }
    }

    .flag {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 44px;
      margin-top: -5px;

      p {
        font-size: 14px;
      }

      img {
        width: 20px;
        margin-left: 5px;
      }
    }
  }
  .tab-active {
    background-color: #3973bb;
    p {
      color: white;
    }
    span {
      color: white;
    }

    h2 {
      color: white;
      border-color: white;
      background-color: white;
      color: #3973bb;
    }
  }
`;

export default Container;
